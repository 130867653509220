import React from "react"
import Img from "gatsby-image"

import SEO from "../components/seo"
import Layout from "../components/layout"
import { graphql } from "gatsby"

const ContactPage = ({ data }) => {
  return (
    <React.Fragment>
      <SEO title="Contact" />
      <Layout>
        <div class="position-relative d-inline-block">
          <Img
            style={{ minWidth: "100vw", height: "50vh" }}
            fluid={data.coverImage.childImageSharp.fluid}
          />
          <div
            className="position-absolute mx-auto my-0 w-75"
            style={{ left: "0", right: "0", top: "40%" }}
          >
            <h1 className="display-4 text-white text-center font-weight-bold">
              Contact Us
            </h1>
          </div>
        </div>
        <div className="container my-5">
          <div className="row">
            <div className="col-12 col-md-4">
              <h2>Office Address</h2>
              <p className="text-muted">
                Jl. Kemang Timur No. 56, RT 07/RW 03, Bangka, Mampang Prpt.,
                Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12730
              </p>
            </div>
            <div className="col-12 col-md-4">
              <h2>E-mail</h2>
              <p className="text-muted">
                Click the following address to drop us an e-mail:{" "}
                <a href="mailto:admin@reyd.co.id">admin@reyd.co.id</a>
              </p>
            </div>
            <div className="col-12 col-md-4">
              <h2>Phone</h2>
              <p className="text-muted">
                Click the following number to text us through WhatsApp:
                <a href="tel:085811749596">+62 858-1174-9596</a>
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default ContactPage

export const query = graphql`
  {
    coverImage: file(relativePath: { eq: "contactPage/contact.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
